/* You can add global styles to this file, and also import other style files */

/** ng-select styles **/
@import "~@ng-select/ng-select/themes/default.theme.css";

:root {
  /** app color palette **/
  --accent: #10419B;
  --accent-highlight: #36CEF0;
  --bright: #FFFFFF;
  --primary: #2C2738;
  --stroke: #DBE2EA;
  --secondary: #756F86;
  --background: #F5F5FB;
  --tint: #E2F1FA;
  --icon: #AFB6BD;
  --muted: #CCCCCC;
  --success: #15CF74;
  --error: #FF7171;
  --warning: #FFAE33;
  --black: #000;
  --header-height: 4.5rem;
  --box-shadow: 0rem 1rem 3.5rem rgba(44, 39, 56, 0.08);
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Inter, Roboto, "Helvetica Neue", sans-serif;
  line-height: 1.25;
  background-color: var(--background);
  color: var(--primary);
}

b {
  font-weight: 600;
}

input, button, select, textarea {
  font-family: Inter, Roboto, "Helvetica Neue", sans-serif;
  line-height: 1.25;
}

app-loader, app-image, svg-icon {
  display: inline-block;
}

svg-icon>svg {
  vertical-align: middle;
}

.rect-14 {
  width: .87rem;
  height: .87rem;
}

.rect-24 {
  width: 1.5rem;
  height: 1.5rem;
}

.color-indicator {
  display: inline-block;
  border:  1px solid var(--stroke);
  margin-right: .75rem;
}

.color-indicator.small {
  width: 12px;
  height : 12px;
}

.color-indicator.medium {
  width: 16px;
  height : 16px;
}

.status-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.status-indicator.success {
  background-color: var(--success);
}

.status-indicator.error {
  background-color: var(--error);
}

.status-indicator.warning {
  background-color: var(--warning);
}

.p-1 {
  padding: .62rem;
}

.p-2 {
  padding: .93rem;
}

.p-l-1 {
  padding-left: .62rem;
}

.p-l-2 {
  padding-left: .93rem;
}

.p-r-1 {
  padding-right: .62rem;
}

.p-r-2 {
  padding-right: .93rem;
}

.m-1 {
  margin: .62rem;
}

.m-2 {
  margin: .93rem;
}

.m-l-1 {
  margin-left: .62rem;
}

.m-l-2 {
  margin-left: .93rem;
}

.m-l-3 {
  margin-left: 1.5rem;
}

.m-r-1 {
  margin-right: .62rem;
}

.m-r-2 {
  margin-right: .93rem;
}

.m-r-3 {
  margin-right: 1.5rem;
}

.m-t-1 {
  margin-top: .62rem !important;
}

.m-t-2 {
  margin-top: .93rem !important;
}

.m-t-3 {
  margin-top: 1.5rem !important;
}

.m-b-1 {
  margin-bottom: .62rem !important;
}

.m-b-2 {
  margin-bottom: .93rem !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

/* Custom Button */

.btn {
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px;
  text-decoration: none;
  color: var(--bright);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}

.btn > * {
  vertical-align: middle;
}

a.btn {
  display: inline-block;
}

.btn.btn-primary {
  background-color: var(--accent);
  border: 1px solid var(--stroke);
}

.btn.btn-primary-outline {
  background-color: transparent;
  border: 2px solid var(--stroke);
  color: var(--accent);
}

.btn.btn-large-primary {
  text-align: center;
  width: 200px;
  padding: 16px;
  background-color: var(--accent);
  border: 2px solid var(--accent);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08), 0px 4px 8px rgba(44, 39, 56, 0.08);
}

.btn.btn-large-bright {
  text-align: center;
  width: 200px;
  padding: 16px;
  background-color: var(--bright);
  color: var(--accent);
  border: 2px solid var(--bright);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08), 0px 4px 8px rgba(44, 39, 56, 0.08);
}


.btn-block {
  display: block;
  width: 100%;
}

.icon-btn {
  padding: 4px;
  background-color: transparent;
  line-height: 1;
  border: none;
  outline: none;
}

.f-1-5 {
  font-size: 1.5rem !important;
}

.f-14 {
  font-size: .875rem;
}

.color-primary {
  color: var(--accent);
}

.color-secondary {
  color: var(--secondary);
}

.text-error {
  color: var(--error);
}

a {
  text-decoration: none;
}

.bg-success {
  background-color: var(--success);
}

.bg-info {
  background-color: var(--accent-highlight);
}

.bg-warning {
  background-color: var(--warning);
}

.bg-danger {
  background-color: var(--error);
}

.text-white {
  color: var(--bright);
}

.c-p {
  cursor: pointer;
}

.ng-select.form-control {
  font-size: 14px;
}

.ng-select.form-control .ng-select-container{
  border-radius: .375rem  !important;
  border : .0625rem solid var(--stroke) !important;
  height: auto;
  padding: .75rem;
  box-shadow: 0rem 0.25rem 0.5rem rgba(44, 39, 56, 0.04) !important;
}

/** dropdown **/
.ng-select.form-control .ng-dropdown-panel {
  border-radius: .375rem;
  border : .0625rem solid var(--stroke);
  padding : .75rem 0; 
  box-shadow: 0rem 0.25rem 0.5rem rgba(44, 39, 56, 0.04),
              0rem 1.25rem 1.25rem rgba(44, 39, 56, 0.04);
}

/** add space between select element and dropdown 
    dropdown can be either placed on top of select or bottom of select 
    depends on whether dropdown is in vissible area of screen  **/
.ng-select.form-control .ng-dropdown-panel.ng-select-bottom {
  margin-top: .25rem;
}

.ng-select.form-control .ng-dropdown-panel.ng-select-top {
  margin-bottom: .25rem;
}

.ng-select.form-control .ng-dropdown-panel-items {
  /** max 4 options in dropdown **/ 
  max-height: 11rem;
  border-radius: inherit;
}

/**selected value wrapper **/
.ng-select.form-control .ng-value-container {
  padding-left: 0;
}

/** placeholder **/
.ng-select.form-control .ng-select-container .ng-value-container .ng-placeholder {
  color : var(--secondary);
}

/** search box wrapper **/
.ng-select.form-control .ng-select-container .ng-value-container .ng-input {
  top: .75rem;
  padding-left: .75rem;
}

/** options **/
.ng-select.form-control .ng-option {
  padding : .75rem .9375rem;
}

.ng-select.form-control .ng-option-selected {
  background-color: var(--tint);
}

/** selected options font-weight is more , so reduce it */
.ng-select.form-control .ng-dropdown-panel .ng-dropdown-panel-items 
.ng-option.ng-option-selected .ng-option-label {
  font-weight: inherit;
}

/** cutomize arrow of select */
.ng-select.form-control .ng-arrow-wrapper .ng-arrow {
  top: 0 ;
  width: 14px ;
  height: 8px ;
  background-position: center ;
  border: none;
  background-image: url('/assets/images/select-arrow.svg');
  transition: transform 200ms ease-in-out;
}

.ng-select.form-control.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  transform: rotate(180deg);
}

/** invalid ng-select **/
.ng-select.form-control.ng-invalid.ng-touched .ng-select-container {
  border : .063rem solid var(--error) !important;
}

.form-group {
  position: relative;
}


.form-group > .label {
  font-size: .875rem; 
  font-weight: 500;
  line-height: 1.25;
  color: var(--secondary);
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group input.form-control,
.form-group .ng-select.form-control {
  margin-bottom: 1.875rem;
}

.form-group .error-feedback {
  color: var(--error);
  position: absolute;
  margin-top: -1.5rem;
  font-size: .75rem;
}

input.form-control {
  display: block;
  width: 100%;
  font-size: .875rem;
  color: var(--primary);
  padding: .875rem; 
  border: .0625rem solid var(--stroke);
  border-radius: .375rem;
  outline: none;
  box-shadow: 0rem 0.25rem 0.5rem rgba(44, 39, 56, 0.04);
}

input.form-control::placeholder{
  color : var(--secondary);
}
input.form-control::-webkit-input-placeholder  {
  color : var(--secondary);
}
input.form-control::-moz-placeholder {
  color : var(--secondary);
}
input.form-control:-ms-input-placeholder {
  color : var(--secondary);
}

input.form-control.ng-invalid.ng-touched {
  border : .063rem solid var(--error);
}

.ng-has-value .ng-placeholder {
  display: none;
}


/** material styles override **/ 

.mat-drawer-inner-container { 
  height: auto !important;
}

.mat-spinner.app-spinner.bright circle {
  stroke: var(--bright);
}

.mat-spinner.app-spinner.accent circle {
  stroke: var(--accent);
}

.app-checkbox.mat-checkbox-indeterminate .mat-checkbox-background,
.app-checkbox.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--accent);
}

.mat-checkbox.app-checkbox .mat-checkbox-ripple {
  display: none;
}

.app-radio-group.mat-radio-group {
  display: inline-block;
  margin-left: 24px;
}

.app-radio-group.block.mat-radio-group {
  display: block;
  margin-left: 0;
}

.app-radio-group.mat-radio-group .app-radio-button.mat-radio-button:nth-last-child(n+2){
  margin-right: 24px;
}

.app-radio-button.mat-radio-button .mat-radio-ripple {
  display: none;
}

.app-radio-button.mat-radio-button.mat-radio-checked.mat-accent .mat-radio-outer-circle {
  border-color : var(--accent)
}

.app-radio-button.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--accent);
}

.app-autocomplete.mat-autocomplete-panel {
  margin: 8px 0;
  border: 1px solid var(--stroke);
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04),
  0px 1.25rem 20px rgba(44, 39, 56, 0.04); ;
}

/** to cover the absolute positioned element */
.app-sidenav-container.mat-sidenav-container {
  z-index: 10;
}
